import * as React from "react"
import {FieldProps, useRecordContext} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
    checked: {
        padding: 0,
        color: "#289AED"
    },
    unchecked: {
        padding: 0
    }
  });

const MyBooleanField:React.FC<FieldProps> = (props) => {
    const {source} = props;
    const record = useRecordContext(props);
    const classes = useStyles();
    if(source && record[source]){
        return <CheckIcon className={classes.checked} />
    }
    return <CloseIcon className={classes.unchecked}/>
}

export default MyBooleanField;