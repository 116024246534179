import { AuthProvider } from "react-admin";
import { login } from "../dataProvider/queries";

type Credentials = {
  email: string;
  password: string;
};

const authProvider: AuthProvider = {
  login: async ({ email, password }: Credentials) => {
    const request = new Request(process.env.REACT_APP_API as string, {
      method: "POST",
      body: JSON.stringify({
        query: login,
        variables: { email, password },
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        if (res.data.authUserLogin.token) {
          localStorage.setItem(
            "auth",
            "Bearer " + res.data.authUserLogin.token
          );
          localStorage.removeItem("not_authenticated");
          return;
        }
        localStorage.setItem("not_authenticated", "true");
        return Promise.reject();
      });
  },
  checkAuth: () => {
    // Required for the authentication to work
    const token = localStorage.getItem("auth");
    if (token) return Promise.resolve();

    return Promise.reject();
  },
  getPermissions: () => {
    // Required for the authentication to work
    const token = localStorage.getItem("auth");
    if (token) return Promise.resolve();

    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
};

export default authProvider;
