import * as React from "react";
import { TextInputProps, useRecordContext } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Rating } from 'react-simple-star-rating';

const useStyles = makeStyles((theme) => ({
    rating: {
        height: "22px"
    }
  }))

const RatingField = (props: TextInputProps) => {
    const {source} = props;
    const record = useRecordContext(props);
    const classes = useStyles();
    if (record[source])
        return <Rating readonly ratingValue={record[source]*20} size={22} className={classes.rating} emptyClassName={classes.rating} />;
    else
        return null;
}

export default RatingField;