import gql from "graphql-tag";

export const login = `
mutation login($email: String!, $password: String!) {
  authUserLogin(email: $email, password: $password) {
    token
  }
}
`

export const getShops = gql`
  query Shops($page: Int, $first: Int) {
    shops(page: $page, first: $first) {
      paginatorInfo {
        total
      }
      data {
        id
        name
        shop_opinions {
          order_id
          order {
            client_email
          }
        }
      }
    }
  }
`;

export const getOrders = gql`
  query Orders($page: Int, $first: Int) {
    orders(page: $page, first: $first) {
      paginatorInfo {
        total
      }
      data {
        id
        client_email
        opinion_hash
      }
    }
  }
`;

export const getShopOpinions = gql`
  query ShopOpinions($page: Int, $first: Int) {
    shopOpinions(page: $page, first: $first) {
      paginatorInfo {
        total
      }
      data {
        id
        file_url
        available_online
        rating
      }
    }
  }
`;

export const getShopOpinion = gql`
  query ShopOpinion($id: ID!) {
    shopOpinion(id: $id) {
      id
      file_url
      available_online
      rating
    }
  }
`;

export const updateShopOpinion = gql`
  mutation ShopOpinionUpdate($id: ID!, $available_online: Boolean) {
    shopOpinionUpdate(input: { id: $id, available_online: $available_online }) {
      id
      file_url
      available_online
    }
  }
`;

export const getOrderProducts = gql`
  query OrderProducts($page: Int, $first: Int) {
    orderProducts(page: $page, first: $first) {
      paginatorInfo {
        total
      }
      data {
        id
        available_online
        name
        file_url
        rating
      }
    }
  }
`;

export const getOrderProduct = gql`
  query OrderOpinion($id: ID!) {
    orderProduct(id: $id) {
      id
      available_online
      name
      file_url
      rating
    }
  }
`;

export const updateOrderProduct = gql`
  mutation OrderProductUpdate($id: ID!, $available_online: Boolean) {
    orderProductUpdate(
      input: { id: $id, available_online: $available_online }
    ) {
      id
      name
      available_online
    }
  }
`;
