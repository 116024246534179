import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  ResourceComponentPropsWithId,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MyPagination from "./MyPagination";

const useStyles = makeStyles((theme) => ({
  id: {
    width: "1%"
  },
  name: {
    width: "99%"
  }
}))

export const ShopsList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={false} pagination={<MyPagination />}>
      <Datagrid>
        <TextField source="id" headerClassName={classes.id} />
        <TextField source="name" headerClassName={classes.name}/>
      </Datagrid>
    </List>
  );
};

export const ShopEdit = (props: ResourceComponentPropsWithId) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
