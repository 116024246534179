import * as React from "react";
import { TextInputProps, useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link } from "@material-ui/core";
import LaunchTwoToneIcon from '@material-ui/icons/LaunchTwoTone';

const useStyles = makeStyles({
  button: {
    padding: "0 10px",
    borderRadius: "6px",
    color: "#289AED",
    fontSize: "0.875rem"
  },
  icon: {
    fontSize: "0.875rem"
  }
});

const OpinionUrlField = (props: TextInputProps) => {
  const { source } = props;
  const record = useRecordContext(props);
  const classes = useStyles();
  return (
    <Link
      underline="none"
      href={process.env.REACT_APP_API?.replace(
        "/graphql",
        "/orders/" + record[source]
      )}
      target="_blank"
    >
      <Button className={classes.button}><LaunchTwoToneIcon className={classes.icon} />Link</Button>
    </Link>   
  );
};

export default OpinionUrlField;

