import React from "react";
import { List, Datagrid, TextField, ResourceComponentProps } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import OpinionUrlField from "./fields/OpinionUrlField";
import MyPagination from "./MyPagination";

const useStyles = makeStyles((theme) => ({
  id: {
    width: "1%"
  },
  client_email: {
    width: "89%"
  },
  opinion_hash: {
    width: "10%"
  }
}))

export const OrderList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <List {...props} exporter={false} bulkActionButtons={false} actions={false} pagination={<MyPagination />}>
      <Datagrid>
        <TextField source="id" headerClassName={classes.id} />
        <TextField source="client_email" headerClassName={classes.client_email} />
        <OpinionUrlField source="opinion_hash" label="Opinion link" headerClassName={classes.opinion_hash} />
      </Datagrid>
    </List>
  );
};
