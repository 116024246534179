import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Edit,
  SimpleForm,
  BooleanInput,
  EditButton,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import VideoField from "./fields/VideoField";
import MyBooleanField from "./fields/MyBooleanField";
import MyPagination from "./MyPagination";
import RatingField from "./fields/RatingField";

const useStyles = makeStyles((theme) => ({
  id: {
    width: "1%",
    color: "red",
  },
  available: {
    width: "10%"
  },
  rating: {
    width: "20%"
  },
  isRecorded: {
    width: "20%"
  },
  editButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}))

export const ShopOpinionsList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={false}
      pagination={<MyPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" headerClassName={classes.id} />
        <MyBooleanField source="available_online" headerClassName={classes.available} />
        <RatingField source="rating" headerClassName={classes.rating} />
        <MyBooleanField source="file_url" label="Opinion recorded" headerClassName={classes.isRecorded} />
        <EditButton basePath="/Shop%20Opinions" className={classes.editButton} />
      </Datagrid>
    </List>
  );
};

const ShopOpinionTitle = ({ record }: any) => {
  return <span>Edit ShopOpinion id: {record ? `${record.id}` : ""}</span>;
};

export const ShopOpinionsEdit = (props: ResourceComponentProps) => {
  return (
    <Edit title={<ShopOpinionTitle />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <VideoField disabled source="file_url" />
        <RatingField source="rating" />
        <BooleanInput
          row={true}
          source="available_online"
          label="available online"
        />
      </SimpleForm>
    </Edit>
  );
};
