import * as React from "react";
import { TextInputProps, useRecordContext } from 'react-admin';

const VideoField = (props: TextInputProps) => {
    const {source} = props;
    const record = useRecordContext(props);
    if (record[source])
        return <video width="100%" style={{maxWidth: 400}} controls><source src={record[source]} /></video>;
    else
        return null;
}

export default VideoField;