import React from "react";
import { Admin, Resource } from "react-admin";
import { createTheme } from '@material-ui/core/styles';
import { ShopsList } from "./components/ShopsList";
import {OrderList} from "./components/OrderList";
import { ShopOpinionsList, ShopOpinionsEdit } from "./components/ShopOpinionsList";
import dataProvider from "./dataProvider"
import { OrderOpinionsEdit, OrderOpinionsList } from "./components/OrderOpinionsList";
import authProvider from "./authProvider/authProvider";
import Login from "./pages/Login"

const theme = createTheme({
  palette: {
    secondary: {
      main: "#289AED",
      light: "#289AED",
      dark: "#289AED"
    },
    primary: {
      main: "#289AED",
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        border: "none"
      }
    },
  }
})

const App = () => {
  return (
    <Admin theme={theme} dataProvider={dataProvider()} authProvider={authProvider} loginPage={Login}>
      <Resource name="Shops" list={ShopsList} />
      <Resource name="Orders" list={OrderList} />
      <Resource name="Shop Opinions" list={ShopOpinionsList} edit={ShopOpinionsEdit} />
      <Resource name="Order Opinions" list={OrderOpinionsList} edit={OrderOpinionsEdit} />
    </Admin>
  );
};

export default App;
