import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Edit,
  SimpleForm,
  BooleanInput,
  EditButton,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MyBooleanField from "./fields/MyBooleanField";
import RatingField from "./fields/RatingField";
import VideoField from "./fields/VideoField";
import MyPagination from "./MyPagination";

const useStyles = makeStyles((theme) => ({
  id: {
    width: "1%"
  },
  available: {
    width: "10%"
  },
  name: {
    width: "30%"
  },
  rating: {
    width: "20%"
  },
  file_url: {
    width: "10%"
  },
  editButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}))

export const OrderOpinionsList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={false}
      pagination={<MyPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" headerClassName={classes.id} />
        <MyBooleanField source="available_online" headerClassName={classes.available} />
        <TextField source="name" headerClassName={classes.name} />
        <RatingField source="rating"  headerClassName={classes.rating}/>
        <MyBooleanField source="file_url" label="Opinion recorded" headerClassName={classes.file_url}/>
        <EditButton basePath="/Order%20Opinions" className={classes.editButton} />
      </Datagrid>
    </List>
  );
};

const OrderOpinionTitle = ({ record }: any) => {
  return <span>Edit OrderOpinion id: {record ? `${record.id}` : ""}</span>;
};

export const OrderOpinionsEdit = (props: ResourceComponentProps) => {
  return (
    <Edit title={<OrderOpinionTitle />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput disabled source="name" />
        <VideoField source="file_url" />
        <RatingField source="rating" />
        <BooleanInput
          row={true}
          source="available_online"
          label="available online"
        />
      </SimpleForm>
    </Edit>
  );
};
